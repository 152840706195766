import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress, Grid, Paper, Tab, Tabs } from "@mui/material";
import { PaperContainer } from "../components/PaperContainer";
import { TabPanel } from "../components/TabPanel";
import { CandidateSidebar } from "../components/profile/CandidateSidebar";
import { AppContext } from "../context/AppContext";
import {
    Candidate,
    JobResponse,
    JobState,
    PlacementResponse,
} from "../interfaces";
import { ResumeList } from "../components/ResumeList";
import { CandidateJobsList } from "../components/CandidateJobsList";
import { PlacementCreate } from "../components/PlacementCreate";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { CandidatePlacementList } from "../components/CandidatePlacementsList";
import { NotesList } from "../components/NotesList";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";
import { WotcScreeningsList } from "../components/WotcScreeningsList";

export const CandidateProfile = () => {
    const navigate = useNavigate();
    const { candidateId } = useParams();
    const { getApi, user: appUser } = useContext(AppContext);
    const [candidate, setCandidate] = useState<Candidate | null>(null);
    const [tabValue, setTabValue] = useState<number>(0);
    const [placeToggle, setPlaceToggle] = useState<boolean>(false);
    const [nominateToggle, setNominateToggle] = useState<boolean>(false);
    const [jobState, setJobState] = useState<JobState>({
        initialized: false,
        loading: false,
        jobs: [] as JobResponse[],
    });
    const [selectedJob, setSelectedJob] = useState<JobResponse | null>(null);

    const fetchCandidate = async () => {
        try {
            const api = await getApi();
            const response = await api.get(`/candidate/${candidateId}`);
            setCandidate(response.data as Candidate);
        } catch (e) {
            handleError(e as AxiosError);
        }
    };

    useEffect(() => {
        fetchCandidate();
    }, []);

    useEffect(() => {
        const addNomination = async (
            candidate: Candidate,
            job: JobResponse,
        ) => {
            try {
                const body = {
                    candidateId: candidate.id,
                    jobId: job.id,
                };
                const api = await getApi();
                const response = await api.post("/pipeline", body);
                navigate(`/pipeline/${response.data.id}`);
            } catch (e) {
                handleError(e as AxiosError);
                setNominateToggle(false);
                setSelectedJob(null);
            }
        };

        if (candidate && selectedJob && nominateToggle) {
            // add nomination
            addNomination(candidate, selectedJob);
        }
    }, [selectedJob, nominateToggle]);

    const fetchJobs = async () => {
        if (jobState.initialized) return;
        setJobState({ ...jobState, loading: true });
        try {
            const api = await getApi();
            const response = await api.get("/job", { params: { open: true } });
            setJobState({
                initialized: true,
                loading: false,
                jobs: response.data as JobResponse[],
            });
        } catch (e) {
            setJobState({ ...jobState, loading: false });
            handleError(e as AxiosError);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const placedAction = (placement: PlacementResponse) => {
        fetchCandidate();
        setSelectedJob(null);
        setPlaceToggle(false);
        setTabValue(5);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleSidebarAction = (action: string) => {
        switch (action) {
            case "nominate":
                setNominateToggle(true);
                setPlaceToggle(false);
                break;
            case "place":
                setNominateToggle(false);
                setPlaceToggle(true);
                break;
            case "logNote":
                setNominateToggle(false);
                setPlaceToggle(false);
                setTabValue(5);
                break;
            case "deleteTalent":
            default:
                setNominateToggle(false);
                setPlaceToggle(false);
                setTabValue(0);
                break;
        }
    };

    return (
        <Grid className="profile-grid" container spacing={2}>
            <Grid item md={3} xs={12}>
                <CandidateSidebar
                    candidate={candidate}
                    user={appUser}
                    buttonActions={handleSidebarAction}
                />
            </Grid>
            <Grid item md={9} xs={12}>
                {placeToggle && (
                    <Paper style={{ marginBottom: "1em" }}>
                        <PaperContainer>
                            <Box>
                                <h2>
                                    <Box display="flex">
                                        <span style={{ width: "100%" }}>
                                            Place Candidate
                                        </span>
                                        <HighlightOffIcon
                                            className="hover-pointer"
                                            onClick={() =>
                                                setPlaceToggle(false)
                                            }
                                        />
                                    </Box>
                                </h2>
                                {!selectedJob && (
                                    <>
                                        <CandidateJobsList
                                            fetchJobs={fetchJobs}
                                            jobs={jobState.jobs}
                                            loading={jobState.loading}
                                            actionType="place"
                                            selectJob={setSelectedJob}
                                        />
                                    </>
                                )}
                                {selectedJob && appUser && (
                                    <PlacementCreate
                                        candidate={candidate as Candidate}
                                        clearJob={() => {
                                            setSelectedJob(null);
                                        }}
                                        job={selectedJob}
                                        placedAction={placedAction}
                                        user={appUser}
                                    />
                                )}
                            </Box>
                        </PaperContainer>
                    </Paper>
                )}
                {nominateToggle && (
                    <Paper style={{ marginBottom: "1em" }}>
                        <PaperContainer>
                            <Box>
                                <h2>
                                    <Box display="flex">
                                        <span style={{ width: "100%" }}>
                                            Nominate Candidate
                                        </span>
                                        <HighlightOffIcon
                                            className="hover-pointer"
                                            onClick={() =>
                                                setNominateToggle(false)
                                            }
                                        />
                                    </Box>
                                </h2>
                                {!selectedJob && (
                                    <>
                                        <CandidateJobsList
                                            fetchJobs={fetchJobs}
                                            jobs={jobState.jobs}
                                            loading={jobState.loading}
                                            actionType="nominate"
                                            selectJob={setSelectedJob}
                                        />
                                    </>
                                )}
                                {selectedJob && <CircularProgress />}
                            </Box>
                        </PaperContainer>
                    </Paper>
                )}
                {!placeToggle && !nominateToggle && (
                    <Paper>
                        <PaperContainer>
                            <Box>
                                <Tabs
                                    onChange={handleTabChange}
                                    value={tabValue}
                                    variant="scrollable"
                                >
                                    <Tab
                                        label="Resume"
                                        aria-controls="candidate-tab-0"
                                    />
                                    <Tab
                                        label="WOTC"
                                        aria-controls="candidate-tab-1"
                                    />
                                    <Tab
                                        label="Education"
                                        aria-controls="candidate-tab-2"
                                    />
                                    <Tab
                                        label="Work History"
                                        aria-controls="candidate-tab-3"
                                    />
                                    <Tab
                                        label="Documents"
                                        aria-controls="candidate-tab-4"
                                    />
                                    <Tab
                                        label="Notes"
                                        aria-controls="candidate-tab-5"
                                    />
                                    <Tab
                                        label="Placements"
                                        aria-controls="candidate-tab-6"
                                    />
                                    <Tab
                                        label="Restrictions"
                                        aria-controls="candidate-tab-7"
                                    />
                                </Tabs>
                            </Box>
                        </PaperContainer>
                        <PaperContainer>
                            <TabPanel
                                index={0}
                                value={tabValue}
                                aria="candidate-tab"
                            >
                                <ResumeList
                                    candidateId={candidate?.id ?? null}
                                />
                            </TabPanel>
                            <TabPanel
                                index={1}
                                value={tabValue}
                                aria="candidate-tab"
                            >
                                <WotcScreeningsList
                                    candidateId={candidate?.id ?? null}
                                />
                            </TabPanel>
                            <TabPanel
                                index={2}
                                value={tabValue}
                                aria="candidate-tab"
                            >
                                Education
                            </TabPanel>
                            <TabPanel
                                index={3}
                                value={tabValue}
                                aria="candidate-tab"
                            >
                                Work History
                            </TabPanel>
                            <TabPanel
                                index={4}
                                value={tabValue}
                                aria="candidate-tab"
                            >
                                Documents
                            </TabPanel>
                            <TabPanel
                                index={5}
                                value={tabValue}
                                aria="candidate-tab"
                            >
                                <NotesList
                                    filter={{ candidateId: candidateId }}
                                />
                            </TabPanel>
                            <TabPanel
                                index={6}
                                value={tabValue}
                                aria="candidate-tab"
                            >
                                <CandidatePlacementList
                                    candidateId={candidate?.id ?? null}
                                />
                            </TabPanel>
                            <TabPanel
                                index={7}
                                value={tabValue}
                                aria="candidate-tab"
                            >
                                Restrictions
                            </TabPanel>
                        </PaperContainer>
                    </Paper>
                )}
            </Grid>
        </Grid>
    );
};
